import $ from 'jquery';
window.jQuery = $;
window.$ = $;
//window.$ = window.jQuery = jQuery;
//window.jQuery = window.$ = require('jquery');
//global.$ = jQuery;
//global.jQuery = $;
//global.jQuery, global.$ = require("jquery");
//global.$ = global.jquery = jquery;
//console.log(jQuery.fn.jquery)

//import 'jquery';
//import 'jquery/src/jquery';
import 'jquery-ui-dist/jquery-ui';
//require('webpack-jquery-ui');
//require('webpack-jquery-ui/css');

//import {} from 'jquery-ujs';