/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Vendor / External rails & non-rails libraries
import '../vendor';

// Initialize custom javascript
import('../javascripts/settings.js');
// import the js cocoon (https://github.com/nathanvda/cocoon/issues/555#issuecomment-530192116)
import('../javascripts/cocoon.js');

// exports js functions with 'exports-loader'
import { bloodhoundSearchTitle } from 'exports-loader?exports=bloodhoundSearchTitle!../javascripts/settings.js';
//import('../../../node_modules/jstree/dist/themes/default/style.min.css');

// Images
require.context('../images/', true, /\.(gif|jp?g|png|ico|svg|eot|ttf|woff|woff2)$/i);

// Stylesheets
import('../stylesheets/index.js.erb');
//require.context('../stylesheets/', true, /\.(css|sass|scss)$/i)

// Font Awesome 5
//import "@fortawesome/fontawesome-free/js/all";
//import "@fortawesome/fontawesome-free/css/all";

/* Register turbo events on document
 * @see {@link https://turbo.hotwired.dev/reference/events} <tt>turbo:load</tt> fires once after the initial page load, and again after every Turbo visit.
 */
document.addEventListener("turbo:load", function() {
console.log('* turbo:load');

      // Initialize tooltips without jQuery
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        container: '#wrapper-layout'
      });
    })

    getTooltip();
    getPopover();
    disposePopoverNavbar();
    bloodhoundSearchTitle();
  }
);

/* Register popover events on document
 * @see {@link https://getbootstrap.com/docs/5.0/components/popovers/#events} <tt>shown.bs.popover</tt> fires when the popover has been made visible to the user
 * @see {@link https://getbootstrap.com/docs/5.0/components/popovers/#dispose} hides and destroys an element’s popover (removes stored data on the DOM element)
*/
document.addEventListener('shown.bs.popover', function (event) {
  var thistarget = $(event.target);
  // hide & destroy any existing popover element but the current one
  //$("[data-bs-toggle='popover']").not(event.target).popover("dispose");

  if (thistarget.is('.dnb-image, .gndnew-image, .viaf-image, .wd-image, .wiki-image, .fachlexikon-image, .other-image')) {
   	if (thistarget.hasClass('dnb-image')) {
      var popover_method = "/gnd_term/gnd_dynamic_popover"
    }
    else if (thistarget.hasClass('viaf-image')) {
      var popover_method = "/gnd_term/viaf_static_or_dynamic_popover"
    }
    else if (thistarget.hasClass('wd-image')) {
      var popover_method = "/gnd_term/wd_dynamic_popover"
    }
    else {
      var popover_method = "/gnd_term/other_static_popover"
    }
    /** AJAX method to build any <i>GND/VIAF/WD/other</i> popover
     * @async
     * @see {@link https://api.jquery.com/jquery.get/|jQuery.get()}
     * @params {number} externid
     * @returns {string} popover_data_json JSON data for '*_popover' partial
     */
    $.get(popover_method, {
         externid:        thistarget.data('subjectheadingid'),
         wdlanguage:      (typeof $("#wdlanguage").data("wdlang") !== "undefined") ? $("#wdlanguage").data("wdlang") : thistarget.data('language'),
      },
      function(response) {
        if (response.success == true) {
          var popover = bootstrap.Popover.getInstance(thistarget);
          if ($(popover) != null) {
            var popover_tip = $(popover.tip);
            //popover_tip.addClass('sh-popover');   // to remove the popover arrow
            popover_tip.find('.popover-body').empty().append(response.popover_data_json);
              // calculate dimension & reposition dynamic popovers
            if (popover_tip.offset() !== undefined) {
              if ((popover_tip.offset().top - $(window).scrollTop()) + popover_tip.height() > window.innerHeight) {
                popover_tip.css('top', - popover_tip.height() + 45);
                $('.popover .popover-arrow').css('visibility', "hidden");
              }
              if (popover_tip.offset().left + popover_tip.width() > $(window).width()) {
                popover_tip.css('left', - popover_tip.width() - 25);
                $('.popover .popover-arrow').css('visibility', "hidden");
              }
            }
          }
        }
        else {
          if (typeof response.popover_data_json !== 'undefined') {
            if (isEmpty(response.popover_data_json) == true) {
              if (response.popover_data_json.match(/^Keine gültige/)) {  // if the response message not empty and begins with...
                window.severity = "warning";
              }
            }
            else {
              window.severity = "error";
            }
            slideUpFlashMessage(severity, response.popover_data_json);
          }
          else {
            slideUpFlashMessage("warning", I18n.js.nodata);
          }
        }
      }
    ).fail(function(jqXHR, textStatus, errorThrown) {
        slideUpFlashMessage("error", errorThrown);    // errorThrown "Internal server error" when gndid=nil 
      }
    )
  }
  else if (thistarget.hasClass('datetime-popover')) {   // for Popover date & time

   /** AJAX method to build the 'date & time' popover
    * @async
    * @see {@link https://api.jquery.com/jquery.get/|jQuery.get()} Load data from the server using a HTTP GET request
    * @params {number} createdat The <tt>Classification</tt>/<tt>Subjectheading</tt> creation date
    * @params {number} updatedat The <tt>Classification</tt>/<tt>Subjectheading</tt> update date
    * @returns {string} htmltable JSON data for 'datetime_popover' partial
    */
    $.get("/home/get_popover_datetime", {
        createdat:        thistarget.data('createdat'),
        updatedat:        thistarget.data('updatedat')
      },
      function(response,err) {
        if (response.success) {
          var popover = bootstrap.Popover.getInstance(thistarget);
          var popover_tip = $(popover.tip);
          popover_tip.find('.popover-body').empty().append(response.htmltable);
        }
      },
      "json"
      )
    }
});
