/** Use ready() to make a function available after the document is loaded (shorthand for $( document ).ready())
 * @see {@link https://www.w3schools.com/jquery/event_ready.asp|$( document ).ready()} The ready event occurs when the DOM (document object model) has been loaded
 */
$(function() {
  // To sanitize options which accept HTML
  bootstrapSanitizer();

  scrollToTopButton();

  /** Set a CSS class to call the dropdowns
   * @see {@link https://www.w3schools.com/bootstrap/bootstrap_ref_js_dropdown.asp|JS Dropdown} A dropdown menu is a toggleable menu that allows the user to choose one value from a predefined list
   */
  $('.dropdown-toggle').dropdown();

  toggleExplainQuery();

/*
  $('.dropdown-menu select, .dropdown-menu textearea').click(function(e) {
    e.stopPropagation();
  })
*/
  /** Caps Lock detection to prevent errors when entering password
     Note. unbind used to avoid keypress to fire the event twice
     * @see {@link https://api.jquery.com/unbind/|unbind( eventType [, handler ] )} Remove a previously-attached event handler from the elements.
     * @see {@link https://api.jquery.com/keypress/|.keypress( handler )} Bind an event handler to the "keypress" JS event, or trigger that event on an element.
     * @see {@link https://www.w3schools.com/jsref/jsref_touppercase.asp|toUpperCase()} Convert a string to uppercase letters (it does not change the original string).
     * @see {@link https://www.w3schools.com/jsref/jsref_tolowercase.asp|toLowerCase()} Convert a string to lowercase letters (it does not change the original string).
  */
  $('#user_password').unbind('keypress').on('keypress', function(e) {
    var s = String.fromCharCode( e.which );
    if ( s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey ) {
      slideUpFlashMessage("warning", I18n.js.password);
    }
  });

    // prevent dropdown from closing on click
  $('.dropdown-menu-large li').on("click.bs.dropdown", function (e) {
    if ($(this).hasClass('sys-filter')) { 
      e.stopPropagation();
    }
  });

  /** Toggle button for 'expert' search
   * @see {@link https://api.jquery.com/change/|.change|.change( handler )} Bind an event handler to the "change" JavaScript event, or trigger that event on an element.
   * @see {@link ttps://api.jquery.com/prop/|.prop()} Get the value of a property for the first element in the set of matched elements or set one or more properties for every matched element.
   * @see {@link https://api.jquery.com/show/|.show()} Display the matched elements
   * @see {@link https://api.jquery.com/hide/|.hide()} Hide the matched elements
   * @see {@link https://api.jquery.com/css/|.css()} Get the value of a computed style property for the first element in the set of matched elements or set one or more CSS properties for every matched element.
  */
  $('#expertsearch').on('change', function() {
  	switch ($(this).prop("checked")) {
  	  case false:
 	    $('#expert-div').hide().css('visibility', 'hidden');
  	    break;
  	  case true:
 	    $('#expert-div').show().css('visibility', 'visible');
  	    break;
    }
  });

  /** JStree to be reloaded if 'classification-modal' is closed from 'btn-close' button
   * @see {@link https://www.w3schools.com/jsref/met_loc_reload.asp} Location reload() Method
   */
  var ModalClas = document.getElementById('classification-modal');
  if (ModalClas != null) {
    ModalClas.addEventListener('hidden.bs.modal', function (event) {
      var host = window.location.origin;
      location.reload();
    });
  }

  /** Show/hide Info Popover on mouseenter/mouseleave events
     * @see {@link https://api.jquery.com/mouseenter/|.mouseenter( handler )} Bind an event handler to be fired when the mouse enters an element, or trigger that handler on an element.
     * @see {@link https://api.jquery.com/mouseleave/|.mouseleave( handler )} Bind an event handler to be fired when the mouse leaves an element, or trigger that handler on an element.
  */
  $('#search-results-table td.resultrow').on("mouseenter", function(event, ui) {
  	var thisobj = $(this);

  	thisobj.attr("data-resultrow-hover", true);
  	var classification_id = thisobj.parent().find('td.resultrow').find('input.classification_id').val();
  	var popoverTitle = "";
     /** AJAX method to get the <tt>Classification</tt> object information to build the 'Classification popover' (for search results)
        * @async
        * @see {@link https://api.jquery.com/jquery.get/|jQuery.get()}
        * @see {@link https://api.jquery.com/show/|.show()} Display the matched elements
        * @params {number} clas_id The selected <tt>Classification</tt> node ID
        * @returns {string} urltable The Libero OPAC URL
        * @returns {string} htmltable JSON data for 'info_popover' partial
        */
       $.get("/home/get_popover_info", {
           clas_id: classification_id
         },
         function(response) {
           if (response.success == true) {
             popoverTitle = "<dd class='nomargin'>" + response.urltable +
             	            "<span class='monospace '<b>" + response.ttext + "</b></span></button>";
             if (response.signedin == true) {
               popoverTitle = popoverTitle + "&nbsp;<sup>" + I18n.js.popover.id + classification_id.toString().padStart(6,"0") + "</sup>";
             }
             if (thisobj.attr("data-resultrow-hover") === 'true') {
               //$('.popover').popover('hide').delay(500); // hide all popovers & wait 0.5 second
               thisobj
                 .css("cursor", "pointer")
                 .popover({
                   animation: false,
                   title: popoverTitle,
                   content: response.htmltable,
                   placement: 'right',
                   html: true,
                   delay: { show: 200, hide: 100 },
                   trigger: 'hover'
                 })
                 .popover('show')
             }
           }
         }
       );
  })
  .on("mouseleave", function (event, ui) {
  	$(this).attr("data-resultrow-hover", false);
    $('.popover').popover('hide');
  });

  /** Click to show/hide the whole GND crossreference list 
   * * @see {@link https://api.jquery.com/toggle/|.toggle( [duration ] [, complete ] )} Display or hide the matched elements.
   */ 
  $('.gnd-list').on("click", function () {
    $('.hiders').toggle();
  });
});

  /** Allow additional HTML elements (i.e. div, button, table) to default Sanitizer whiteList 
   * @summary Sanitizer for Tooltip and Popover templates has been introduced since Bootstrap v.4.3.1
   * @function <b>bootstrapSanitizer</b>
   * @see {@link https://getbootstrap.com/docs/4.3/getting-started/javascript/#sanitizer}
   */
  function bootstrapSanitizer() {
    var current_bs_version = bootstrap.Tooltip.VERSION;
    if (compareVersions(current_bs_version, "5.0.0") != -1) {  // only if the current version >= "5.0.0"
      var internalAllowList = bootstrap.Tooltip.Default.allowList
  	  internalAllowList.div = []
  	  internalAllowList.button = ['data-bs-title']
  	  internalAllowList.span = ['onclick', 'title', 'data-bs-toggle']
  	  internalAllowList.table = []
  	  internalAllowList.tbody = []
  	  internalAllowList.dd = []
  	  internalAllowList.th = []
  	  internalAllowList.td = []
  	  internalAllowList.tr = []
  	  if ((internalAllowList.img.indexOf('data-bs-toggle') == -1) &&
  	      (internalAllowList.img.indexOf('data-bs-trigger') == -1) &&
  	      (internalAllowList.img.indexOf('data-subjectheadingid') == -1)) {  // check if the value exists in an array
  	    internalAllowList.img = internalAllowList.img.concat(['data-bs-toggle','data-bs-trigger','data-bs-content','data-subjectheadingid','data-language','class'])
  	  }
    }
  }

  /** Scroll To Top Button (Page jump)
   * @function <b>scrollToTopButton</b>
   * @see {@link https://api.jquery.com/scrollTop/|scrollTop()} Get the current vertical position of the scroll bar for the first element in the set of matched elements or set the vertical position of the scroll bar for every matched element
   * @see {@link https://api.jquery.com/fadein/|.fadeIn( [duration ] [, complete ] )} Display the matched elements by fading them to opaque
   * @see {@link https://api.jquery.com/fadeout/|.fadeOut( [duration ] [, complete ] )} Hide the matched elements by fading them to transparent
   * @see {@link https://api.jquery.com/animate/|.animate()} Perform a custom animation of a set of CSS properties
   * @see {@link https://jqueryui.com/tooltip/|tooltip()} Customizable, themeable tooltips, replacing native tooltips
   * @see {@link https://bootsnipp.com/snippets/featured/link-to-top-page)
  */
  function scrollToTopButton() {
    $(window).scroll(function () {
      if ($(this).scrollTop() > 50) { 
        $('#back-to-top').fadeIn(); 
      }
      else {
        $('#back-to-top').fadeOut();
      }
    });

      // click event to scroll to top
    $('#back-to-top').click(function () {
      $('#back-to-top').removeClass('visible').addClass('invisible');
      $('body,html').animate({
          scrollTop: 0
        },
        800 
      );
      return false;
    });
       
    $('#back-to-top').removeClass('invisible').addClass('visible');
  };

  /** Initialize tooltips
   *  @see {@link https://www.w3schools.com/bootstrap/bootstrap_ref_js_tooltip.asp|JS Tooltip} The Tooltip plugin is small pop-up box that appears when the user moves the mouse pointer over an element.
   */
  window.getTooltip = function() {
    $('#wrapper-layout').tooltip({
        // select a specified element or all elements with data-bs-toggle="tooltips" in the document
      selector: '[data-bs-toggle="tooltip"]'  // adds the tooltip to a specified selector
    });
/*
      // Initialize tooltips without jQuery
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        container: '#wrapper-layout'
      });
    });
*/
  };

  /** Initialize popovers
   * @summary Popover is a pop-up box that appears when the user clicks on an element, in comparison with tooltips it can contain much more content
   * @function <b>getPopover</b>
   * @see {@link https://getbootstrap.com/docs/5.0/components/popovers/}
   * @see {@link https://getbootstrap.com/docs/5.0/components/popovers/#events | show.bs.popover} This event fires immediately when the show instance method is called
   */
  import Throbber from '../images/throbber.gif';

  window.getPopover = function() {
    destroyJstreePopover();

    var bsPopover = new bootstrap.Popover(document.body, {
      container: document.querySelector("#wrapper-layout"),
      html: true,
      customClass: "popover-zindex",
      selector: '.dnb-image, .gndnew-image, .viaf-image, .wd-image, .wiki-image, .fachlexikon-image, .other-image, .datetime-popover',
      trigger: 'hover focus',
      content: function() {
        return I18n.js.loading + '<img src=' + Throbber + '/>';
      },
      placement: 'right',
    });
  };

  /**
   * Destroy any existing popover element when click on navbar menu
   * @function <b>disposePopoverNavbar</b>
   */
  window.disposePopoverNavbar = function() {
    $('.nav-item').on('click', function() {
      disposeJstreePopover();
    });
  };

  /**
   * Convert a template string into HTML DOM nodes
   * @param  {String} str The template string
   * @return {Node}       The template HTML
   */
  var stringToHTML = function (str) {
	var parser = new DOMParser();
	var doc = parser.parseFromString(str, 'text/html');
	return doc.body;
  };

  /** Translate the <tt>errorThrown</tt> received from the Bloodhound callback function 
   * @function <b>bloodhoundError</b>
   * @param errorThrown {string} the textual portion of the HTTP status of the Bloodhound callback function failure response
   */
  window.bloodhoundError = function(errorThrown) {
  	var errmsg;
  	switch(errorThrown) {
      case "Internal Server Error":
        errmsg = I18n.js.flash.index_connection;
        break;
      case "Unauthorized":
        errmsg = I18n.js.flash.unauthorized;
        break;
      default:
        errmsg = errorThrown;
        break;
    }
    return errmsg;
  }

  /** Hides and destroys the <tt>result-row</tt> popover
   * @function <b>destroySearchPopover</b>
   * @see {@link https://www.w3schools.com/bootstrap/bootstrap_ref_js_popover.asp|JS Popover} A pop-up box that appears when the user clicks on an element
   * @see {@link https://getbootstrap.com/docs/5.0/components/popovers/#dispose|.popover('dispose')} Hides and destroys an element’s popover
   */
/*
  function destroySearchPopover() {
    $('.result-row').find(".url-link").popover('dispose');
  }
*/

  /** Destroy all instances of Bootstrap Popover having specific class name / Hides and destroys the <tt>jstree</tt> popover
   * @function <b>closePopover</b>
   * @see {@link https://www.w3schools.com/bootstrap/bootstrap_popover.asp|Bootstrap Popover Plugin}
   * @see {@link https://getbootstrap.com/docs/5.0/components/popovers/#dispose|.popover('dispose')} Hides and destroys an element’s popover
   */
  window.destroyJstreePopover = function() {
    $('.popover-close').click(function() {
      disposeJstreePopover();
    });
  };

  /** Destroy any existing jstree tooltip
   * @see {@link https://getbootstrap.com/docs/4.0/components/popovers/#popoverdispose|.tooltip("dispose")} Hides and destroys an element’s tooltip
   */
  function disposeJstreePopover() {
    $(".jstree-anchor").popover('dispose');
  }

  /** Reload the current document if move modal window is closed
   * @see {@link https://www.w3schools.com/jsref/met_loc_reload.asp} Location reload() Method
   */
  window.reloadJstree = function() {
    $('#modal-dialog-move').find('button.close').find('span').on('click', function(e) {
      window.location.reload();
    });
  };

  /** Remove any existing popover element
   * @see {@link https://api.jquery.com/remove/|.remove()} Remove the set of matched elements from the DOM
   */
  window.removePopover = function() {
    $('.popover').remove();
  }

  /**
   * Copy value to clipboard
   * @function <b>copyToClipboard</b>
   * @see {@link https://www.w3schools.com/jsref/met_document_execcommand.asp|execCommand()} Executes the specified command for the selected part of an editable section
   * @see {@link https://api.jquery.com/appendto/.appendTo()} Insert every element in the set of matched elements to the end of the target
   * @see {@link https://api.jquery.com/select/|select( handler )} Bind an event handler to the "select" JavaScript event, or trigger that event on an element.
   * @see {@link https://api.jquery.com/remove/|.remove()} Remove the set of matched elements from the DOM
   * @see {@link https://www.sharmaprakash.com.np/javascript/copying-value-from-variable-to-clipboard}
   * @params {content} the value to be copied to clipboard
   * @params {dummy} the input element to temporarily hold value
   */
  window.copyToClipboard = function(content) {
    var dummy = $('<input>').val(content).appendTo('body').select();
    document.execCommand('copy')
    dummy.remove();
  }

  /** Slide up a notation flash message after copy to clipboard succeded
   * @function <b>clipboardNotationFlashMessage</b>
   */
  window.clipboardNotationFlashMessage = function(notation, nodeid) {
    slideUpFlashMessage("success", I18n.js.jstree.notation.success + "<b>" + notation + "</b>");
  }

  /** Check if value is an empty string
   * @function <b>isEmpty</b>
   * @see {@link https://stackoverflow.com/questions/1812245/what-is-the-best-way-to-test-for-an-empty-string-with-jquery-out-of-the-box
   */
  window.isEmpty = function(value) {
    return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null;
  }

  /** Show then auto-hides the flash messages 
   * @function <b>slideUpFlashMessage</b>
   * @param alertcls {string} Bootstrap alert class
   * @param msg {string} Flash error message
   * @see {@link https://api.jquery.com/show/|show()} Display the matched elements
   * @see {@link https://api.jquery.com/fadeTo/|fadeTo(duration, opacity)} Adjust the opacity of the matched elements
   * @see {@link https://api.jquery.com/html/|html(htmlString)} Get the HTML contents of the first element in the set of matched elements
   * @see {@link https://api.jquery.com/slideup/|slideUp(duration)} Hide the matched elements with a sliding motion 
   * @see {@link https://api.jquery.com/hide/|.hide()} Hide the matched elements
   */
  window.slideUpFlashMessage = function(alertcls, flashmsg) {
  	switch(alertcls) {
  	  case 'error':
  	    window.severity = I18n.js.flash.error
  	    break;
  	  case 'warning':
  	    window.severity = I18n.js.flash.warning
  	    break;
  	  case 'success':
  	    window.severity = I18n.js.flash.success
  	    break;
  	  case 'info':
  	    window.severity = I18n.js.flash.info
  	    break;
    }

console.log("slideUpFlashMessage: " + flashmsg);
    const classAlert = "#js-" + alertcls + "-alert"

    $(classAlert).show()
                 .fadeTo(3000, 500)
                 .html("<div><strong>" + severity + "</strong>&nbsp;" + flashmsg + "</div><div class='d-flex justify-content-end'>" +
                       "<button type='button' class='btn-close' data-bs-dismiss='alert' aria-hidden='true' aria-label='Close'></button></div>")
                 .slideUp(1000, function() {
                    $("#js-" + alertcls + "-alert").attr("style", "display:none !important; margin-bottom: 70px !important")
                                                   .hide()
                 });
  }

  function bloodhoundSearchTitle() { 
    /** @constant {number} */
    const TT_LIMIT = "20"; 

    /** Return the <tt>Subjectheading</tt> objects from <tt>Classification</tt> New/Edit modal window
     * @async
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/bloodhound.md|Bloodhound} Bloodhound is the typeahead.js suggestion engine
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/bloodhound.md#new-bloodhoundoptions|new Bloodhound}
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/bloodhound.md#options|Bloodhound Options} Several options to configure
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/bloodhound.md#remote|Bloodhound remote} Load data from a remote location
     * @see {@link https://twitter.github.io/typeahead.js/examples|Bloodhound examples}
     * @params {object[]} datum The fields to be searched thru'
     * @returns {object[]} an array of <tt>Subjectheading</tt> objects
     */
    var search_title = new Bloodhound({
      datumTokenizer: function (datum) {
        return Bloodhound.tokenizers.whitespace(datum);
      },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: '/home/search_navigation?query=%QUERY',
        prepare: function(query, settings) {
          return $.extend(settings, {
            url: settings.url.replace('%QUERY', encodeURIComponent(query)),
            error: function(jqxhr, textStatus, errorThrown) {
              var errmsg = bloodhoundError(errorThrown);
              slideUpFlashMessage("error", errmsg);
              AjaxError401(jqxhr);
            }
          });
        },
        wildcard: "%QUERY",
        filter: function(response) {
//console.log("response = ",response);
          return response;
        },
        transform: function(response) {
          console.log('transform', response);
          return response.items;
        },
      }
    });

    /** Bloodhound initialization
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/bloodhound.md#bloodhoundinitializereinitialize|Bloodhound#initialize} initialize the Bloodhound suggestion engine
     */
    search_title.initialize();

    /** Call the <b>Typeahead</b> plugin
     * @see {@link https://twitter.github.io/typeahead.js/|typeahead.js} Twitter's Typeahead Search plugin
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#jquerytypeaheaddestroy|typeahead('destroy')} Removes typeahead functionality and reverts the input element back to its original state
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#options|Typeahead options} Several options to configure
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#custom-events|Custom Events} <b>typeahead:asyncrequest</b> Fired when an async request for suggestions is sent
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#custom-events|Custom Events} <b>typeahead:asynccancel</b> Fired when an async request is cancelled
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#custom-events|Custom Events} <b>typeahead:asyncreceive</b> Fired when an async request completes 
     * @see {@link https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#custom-events|Custom Events} <b>typeahead:select</b> Fired when a suggestion is selected
     * @see {@link https://api.jquery.com/select/|select( handler )} Bind an event handler to the "select" JavaScript event, or trigger that event on an element. 
     */
    var typeaheadReferenceSearch = $('#search-subject-heading, #search-subject-headings-nav')
      .typeahead('destroy') // Destroy the current instance if it's available
      .typeahead({
        autoselect: false,
    	hint: true,
        highlight: true,
        minLength: 1,
      },
      { name: 'search_title',
        limit: TT_LIMIT,     // by default Typeahead shows only 5 suggestions
        displayKey: 'title',
        source: search_title.ttAdapter(),
        templates: {
          empty: [
            '<div class="empty-message">',
            I18n.js.typeahead.noresults,
            '</div>'
          ].join('\n'),
          suggestion: 
            Handlebars.compile([
              '<div class="tt-suggestion">',
                '{{title}}',
              '</div>'
            ].join('')),
        },
        engine: Handlebars,
        updater: function(selection) {
      	  console.log(selection);
        }
      })
      .on('typeahead:asyncrequest', function() {
        $('.typeahead').addClass('loading');
      })
      .on('typeahead:asynccancel typeahead:asyncreceive', function() {
        $('.typeahead').removeClass('loading');
      });

      /** Set focus to <b>search-subject-heading</b> field
       * @see {@link https://api.jquery.com/focus/|focus( handler )} Bind an event handler to the "focus" JavaScript event, or trigger that event on an element.
       */ 
      //var focus_search_field = $('#search-subject-heading').focus();
      if (!$('#search-subject-heading').is(":focus")) {
        $('#search-subject-heading').focus();
      }
      // close the suggestion menu if the current value of the typeahead isn't empty
      if ((typeaheadReferenceSearch.typeahead('val') != undefined) || (typeaheadReferenceSearch.typeahead('val') != null)) {
        typeaheadReferenceSearch.typeahead('close');
      }
  };


  /** Toggle between ES explain query results
   * @function <b>toggleExplainQuery</b>
   * @see {@link https://api.jquery.com/fadein/|.fadeIn( [duration ] [, complete ] )} Display the matched elements by fading them to opaque
   * @see {@link https://api.jquery.com/fadeout/|.fadeOut( [duration ] [, complete ] )} Hide the matched elements by fading them to transparent
   * @see {@link https://api.jquery.com/fadetoggle/|.fadeToggle( [duration ] [, easing ] [, complete ] )} Display or hide the matched elements by animating their opacity
   * @see {@link https://api.jquery.com/hide/|.hide()} Hide the matched elements
   * @see {@link https://jsfiddle.net/BramVanroy/pHCN3/2/}
   */
  function toggleExplainQuery() {
    $('a.res-btn').on('click', function(e) {
      var this_id = $(this).attr("id");
        // hide any opened result
      $(".es_results:not('." + this_id + "')").fadeOut("fast");
        // display/hide result of the clicked element
      $(".es_results." + this_id).fadeToggle("fast", function() {
        if ($(this).is(":hidden")) {
          $(this).show().fadeOut("fast");
        }
        else {
          $(this).hide().fadeIn("fast");
        }
      });
    });
  }

  function isPositiveInteger(x) {
    // http://stackoverflow.com/a/1019526/11236
    return /^\d+$/.test(x);
  }

  /**
   * Compare two software version numbers (e.g. 4.3.1)
   * @returns  1  if a > b
   * @returns -1  if a < b
   * @returns  0  if a == b
   * @see {@link http://stackoverflow.com/a/6832721/11236}
   */
  function compareVersions(a_components, b_components) {
    if (a_components === b_components) {
      return 0;
    }

    var partsNumberA = a_components.split(".");
    var partsNumberB = b_components.split(".");

    for (var i = 0; i < partsNumberA.length; i++) {
      var valueA = parseInt(partsNumberA[i]);
      var valueB = parseInt(partsNumberB[i]);

        // A bigger than B
      if (valueA > valueB || isNaN(valueB)) {
        return 1;
      }

        // B bigger than A
      if (valueA < valueB) {
        return -1;
      }
    }
  }
